<template>
  <div class="homepage">
    <div class="b24">
      <HeroSection />
      <PillScrollerSection v-if="$auth.loggedIn" />
      <UserOrderSection v-if="isPersonalized && userHasOrders" />
    </div>
    <section v-if="activeSection.includes(2)">
      <Top10WinesSection v-if="top10Wines" :top10-wines-array="top10Wines" />
      <HomeLogobanSection />
      <HomeWineclubSection />
    </section>
    <section v-if="activeSection.includes(3)">
      <ShopCollectionsSection :is-personalized="isPersonalized" />
    </section>
    <section v-if="activeSection.includes(4)">
      <HomeRestaurantsSection />
      <CustomerOpinionSection />
    </section>
    <section v-if="activeSection.includes(5)">
      <FeaturedCollectionsSection />
    </section>
    <JoinClub10pctModal
      v-if="clubDisplay.eligible && isModalVisible"
      :credit-amount="clubDisplay.creditAmount"
      :date="clubDisplay.orderDate"
      @close="closeModal"
    />
  </div>
</template>

<script>
import { showPersonalized } from '~/../common/services/customerServices'
import ga4 from '~/../common/utils/ga4'

export default {
  name: 'WineAccessHomepage',
  components: {
    FeaturedCollectionsSection: () =>
      import('~/components/Homepage/FeaturedCollectionsSection.vue'),
    CustomerOpinionSection: () =>
      import('~/components/Homepage/CustomerOpinionSection.vue'),
    HomeRestaurantsSection: () =>
      import('~/components/Homepage/HomeRestaurantsSection.vue'),
    ShopCollectionsSection: () =>
      import('~/components/Homepage/ShopCollectionsSection.vue'),
    HomeWineclubSection: () =>
      import('~/components/Homepage/HomeWineclubSection.vue'),
    HomeLogobanSection: () =>
      import('~/components/Homepage/HomeLogobanSection.vue'),
    Top10WinesSection: () =>
      import('~/components/Homepage/Top10WinesSection.vue'),
    UserOrderSection: () =>
      import('~/components/Homepage/UserOrderSection.vue'),
    PillScrollerSection: () =>
      import('~/components/Homepage/PillScrollerSection.vue'),
    HeroSection: () => import('~/components/Homepage/HeroSection.vue'),
    JoinClub10pctModal: () =>
      import('~/components/Club/JoinClub10pctModal.vue'),
  },
  data() {
    return {
      userHasOrders: false,
      top10Wines: [],
      preloadHeroImage: '',
      activeSection: [],
      isModalVisible: true,
      clubDisplay: {
        eligible: false,
        orderDate: '',
        creditAmount: 0,
      },
    }
  },
  head() {
    // allow dynamically head updates
    return this.dynamicHead
  },
  computed: {
    loggedIn() {
      return this.$auth.loggedIn
    },
    isPersonalized() {
      return showPersonalized(this.$auth)
    },
    dynamicHead() {
      return {
        link: [
          {
            rel: 'preload',
            as: 'image',
            href: this.preloadHeroImage,
            key: 'hero-preload',
          },
        ],
      }
    },
  },
  mounted() {
    // eslint-disable-next-line nuxt/no-env-in-hooks
    if (process.client) {
      // update hero images dynamically
      this.screenWidth = window.innerWidth
      this.updateHeroPreload()
      this.progressiveLazyLoadSections()
      window.addEventListener('resize', this.updateHeroPreload)
      // window.addEventListener('scroll', this.updateSection)
      if (this.clubDisplay.eligible) {
        ga4.track('join_club_10_pct_popup_seen')
      }
    }
  },
  beforeDestroy() {
    // eslint-disable-next-line nuxt/no-env-in-hooks
    if (process.client) {
      // removes custom event
      window.removeEventListener('resize', this.updateHeroPreload)
      // window.removeEventListener('scroll', this.updateSection)
    }
  },
  // Runs before the component is mounted (before it's added to the DOM).
  async created() {
    try {
      const response = await this.$axios.get(
        '/api/homepage/homepage-hero-index-data'
      )
      this.userHasOrders = response.data.user_orders
      this.top10Wines = response.data.top_10_wines
    } catch (error) {
      console.error('Error fetching homepage hero index data:', error)
    }
    try {
      const response = await this.$axios.get('api/customer/add_club_get_10_pct')
      this.clubDisplay = {
        eligible: !!response.data.eligible,
        orderDate: response.data.order_date || '',
        creditAmount: response.data.credit_amount || 0,
      }
    } catch (error) {
      console.error('Error fetching add club get 10pct data: ', error)
    }
  },
  methods: {
    updateHeroPreload() {
      // eslint-disable-next-line nuxt/no-env-in-hooks
      if (process.client) {
        this.screenWidth = window.innerWidth
        const cloudfrontBase =
          process.env.CLOUDFRONT_BASE || 'https://static.qa-wineaccess.com/'

        if (this.isPersonalized) {
          if (this.screenWidth >= 768) {
            // contemplates resolutions sizes equal or up to 1024
            this.preloadHeroImage = `${cloudfrontBase}images/home-personalized-large.webp`
          } else {
            this.preloadHeroImage = `${cloudfrontBase}images/home-personalized-small.webp`
          }
        } else if (this.screenWidth >= 1024) {
          this.preloadHeroImage = `${cloudfrontBase}images/home-large.webp`
        } else if (this.screenWidth >= 768) {
          this.preloadHeroImage = `${cloudfrontBase}images/home-medium.webp`
        } else {
          this.preloadHeroImage = `${cloudfrontBase}images/home-small.webp`
        }
      }
    },
    progressiveLazyLoadSections() {
      const delay = 2000
      const sectionsQueue = [2, 3, 4, 5]
      sectionsQueue.forEach((section, index) => {
        setTimeout(() => {
          this.activeSection.push(section)
        }, (index + 1) * delay) // Delay each section by 2 seconds
      })
    },
    closeModal() {
      this.isModalVisible = false
    },
  },
}
</script>
